import React, { useEffect } from "react";
import "../styles/headingnet.scss"

function Heading() {
  return (
    <div className="container bg-heading-net">
      <div className="heading-net">
        <div className="heading-net-wrapper">
        <h1 className="title">PRIVATE<br/>MEMBERSHIP</h1>
        
        <div className="description">VECTOR <div className="green" style={{color: "#00AE73"}}>NET</div>WORK</div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
