import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/networkdesc.scss"
import Arrow from "../icons/arrow";

function NetworkDesc() {
  return (
    <div className="container" id="network">
        <div className="networkdesc-wrapper" href="https://t.me/tribute/app?startapp=sgUS">
            <Arrow/>
              <div className="networkdesc-title"><h2>стань частью большой истории</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="/network.png"/>
                <div className="networkdesc-description">
                  <p className="description">мы создаем пространство для роста талантливых и амбициозных людей. приоритетный доступ участников к мероприятиям, услугам  и возможностям сообщества. только ты и правильное окружение.
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>Ставь цели и достигай</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image"  src="/goals.png"/>
                <div className="networkdesc-description">
                  <p className="description">скорость - основа результата.
участники ставят цели на месяц, делятся советами и
работают с трекерами, которые уже смогли
заработать на интересующей участника теме.
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>Работаем за идею</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="/idea.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">средства за участие в vector network идут на поддержку работы наших трекеров - людей с ценным опытом, которым они готовы поделиться.

вы получаете нужные связи и окружение, нацеленное на результат
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>Знакомства и связи</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="/moneytalks.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">общайся и обменивайся опытом с другими участниками.
мероприятия, чаты, ценная информация. эксклюзивный
доступ к услугам и материалам Vector.

твой шанс стать частью самого инновационного
сообщества снг.
</p>
                </div>
              </div>

            </div>
            <div className="button-wrapper">
            <a href="https://t.me/tribute/app?startapp=sgUS" className="button"><p>ВСТУПИТЬ</p></a>
          </div>
        </div>
    </div>
  );
}

export default NetworkDesc;
