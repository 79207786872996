import React, { useEffect, useState } from "react";
import "../styles/navbar.scss";

function Navbar({buttons=true}) {


  const [navbar, setNavbar] = useState("")

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 66) {
      setNavbar("solid")
    } else {
      setNavbar("")
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })


  return (
    <div className="container">
      <div className={"navbar-wrapper "+ navbar}>
        <a style={{color: "white"}} href="/"className="navbar-logo">
            <img className="logo" width={"70px"} src="vector.png" alt=""/>
            {/* <h3 >Vector</h3> */}
        </a>
        <div className="navbar-tap-to">
          <div className="button-wrapper">
            <a className="button primary" href="#network"><p>NETWORK</p></a>
          </div>
          {/* <div className="button-wrapper">
            <a className="button primary" href="#academy"><p>УСЛУГИ</p></a>
          </div> */}
          <div className="button-wrapper">
            <a className="button primary" href="/community"><p>Общение</p></a>
          </div>
        </div>
        {buttons ?
        <div className="navbar-login">
          <div className="button-wrapper">
            <a href="/network" className="button"><p>ПРИСОЕДИНИТЬСЯ</p></a>
          </div>
          {/* <div className="button-wrapper">
            <a href="/network" className="button contour"><p>ВОЙТИ</p></a>
          </div> */}
        </div>: <></>}
      </div>
    </div>
  );
}

export default Navbar;
